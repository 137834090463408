import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230823-Rate-Sheet.pdf";
const date = "23.08.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Wednesday 30 August 2023 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p>We’ve increased our maximum mortgage term to 40 years for all new residential applications on a capital repayment basis. Any additional borrowing, either standalone or in conjunction with a remortgage, can now also be taken over 40 years.</p>
    <p>Please note, for residential applications where there is any element of interest only, the current maximum term of 25 years will continue to apply.</p>
    <p>We have also increased the maximum term to 40 years for Buy to Let applications on both capital repayment and interest only.</p>
    <p>There are no changes to any of our interest rates at this time and our rate sheet and product codes remain unchanged.</p>
  </NewsArticleLayout>
);

export default Article;
